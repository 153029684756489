/* eslint-disable @typescript-eslint/no-explicit-any */
export const registrations = [
    {
        name: 'ArticleBanner',
        import: () => import('@stories/Widgets/ArticleBanner/ArticleBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ArticleListing',
        import: () => import('@stories/Widgets/ArticleListing/ArticleListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'BackToTopBtn',
        import: () => import('@stories/Components/Global/BackToTopBtn/BackToTopBtn'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ColumnedLayout',
        import: () => import('@stories/Widgets/ColumnedLayout/ColumnedLayout'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ContactFormSection',
        import: () => import(/* webpackChunkName: "ContactFormSection" */ '@stories/Widgets/ContactFormSection/ContactFormSection'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CtaCards',
        import: () => import('@stories/Widgets/CtaCards/CtaCards'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Downloads',
        import: () => import('@stories/Widgets/Downloads/Downloads'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Footer',
        import: () => import(/* webpackChunkName: "Footer" */ '@stories/Widgets/Global/Footer/Footer'),
        config: {
            hydrate: 'never',
        },
    },
    {
        name: 'GenericListing',
        import: () => import('@stories/Widgets/GenericListing/GenericListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'GoogleMapWidget',
        import: () => import('@stories/Widgets/GoogleMapWidget/GoogleMapWidget'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Header',
        import: () => import(/* webpackChunkName: "Header" */ '@stories/Widgets/Global/Header/Header'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'HeroBannerCarousel',
        import: () => import('@stories/Widgets/HeroBannerCarousel/HeroBannerCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'HighlightedInformation',
        import: () => import('@stories/Components/Content/HighlightedInformation/HighlightedInformation'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Iframe',
        import: () => import('@stories/Components/Content/Iframe/Iframe'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ImageGallery',
        import: () => import('@stories/Components/Carousels/ImageGallery/ImageGallery'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ImageText5050',
        import: () => import('@stories/Widgets/ImageText5050/ImageText5050'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'InnerPageBanner',
        import: () => import('@stories/Widgets/InnerPageBanner/InnerPageBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'USPCarousel',
        import: () => import('@stories/Widgets/USPCarousel/USPCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'JoinTodayBanner',
        import: () => import('@stories/Widgets/JoinTodayBanner/JoinTodayBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'LatestEvents',
        import: () => import('@stories/Widgets/LatestEvents/LatestEvents'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'LatestNews',
        import: () => import('@stories/Widgets/LatestNews/LatestNews'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'NotificationBar',
        import: () => import(/* webpackChunkName: "NotificationBar" */ '@stories/Components/Global/NotificationBar/NotificationBar'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Profiles',
        import: () => import('@stories/Widgets/Global/Profiles/Profiles'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'RelatedCards',
        import: () => import('@stories/Widgets/RelatedCards/RelatedCards'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'RichText',
        import: () => import('@stories/Widgets/RichText/RichText'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SponsorsLogoList',
        import: () => import('@stories/Widgets/SponsorsLogoList/SponsorsLogoList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'USPInformationCards',
        import: () => import('@stories/Widgets/USPInformationCards/USPInformationCards'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'InPageNavigation',
        import: () => import('@stories/Components/Global/InPageNavigation/InPageNavigation'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SearchResultsListing',
        import: () => import('@stories/Widgets/SearchResultsListing/SearchResultsListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'QuoteBlock',
        import: () => import('@stories/Widgets/Global/QuoteBlock/QuoteBlock'),
        config: {
            hydrate: 'in-view',
        },
    },
];
